/**=====================
     31. pagination CSS Start
==========================**/
.page-link {
  color: $primary-color !important;
}
.page-item {
  &.active {
    .page-link {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
      color: $white !important;
    }
  }
}

/*test Nav bar color */
$page-name:theme,
primary,
secondary,
success,
danger,
info,
dark,
warning;
$page-color:$primary-color,
$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$dark-color,
$warning-color;
@each $var in $page-name {
  $i: index($page-name, $var);
  .pagination-#{$var}{
    .page-item{
      .page-link{
        color: nth($page-color, $i);
      }
      &.active{
        .page-link{
          color: $white;
          background-color:  nth($page-color, $i);
          border-color:  nth($page-color, $i);
        }
      }
    }
  }
}
.page-item{
  &:first-child{  
    .page-link {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }  
  &:last-child{  
    .page-link {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}
.pagination-sm {
  .page-item{
    &:first-child{  
      .page-link {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }  
    &:last-child{  
      .page-link {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}
.pagination-lg {
  .page-item{
    &:first-child{  
      .page-link {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
    }  
    &:last-child{  
      .page-link {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }
}
.pagination-space {
  .pagination-top {
    margin-top: 15px;
  }
  p {
    margin-bottom: 5px;
  }
}
/**=====================
     31. pagination CSS Ends
==========================**/