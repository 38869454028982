@import "~bootstrap-scss/bootstrap.scss";
 
@import "style";

@import "~ngx-toastr/toastr";
@import "~chartist/dist/scss/chartist.scss";

@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

@import "sweetalert2/src/sweetalert2.scss";

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~dropzone/dist/min/dropzone.min.css";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "~dragula/dist/dragula.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~ngx-simplemde/src/index.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~angular-archwizard/archwizard.scss";
@import "todo.scss";
@import "photoswipe.scss";
@import "~leaflet/dist/leaflet.css";
@import "~@ctrl/ngx-emoji-mart/picker";


