/**=====================
    19.  Touchspin  CSS Start
==========================**/
.touchspin-vertical-tab {
  .touchspin-vertical {
    height: unset;
  }
}
.bootstrap-touchspin {
  .input-group-prepend {
    &:first-child {
      .input-group {
        border-radius: 15px 0 0 15px;
      }
    }
  }
  .input-group-append,
  .input-group-prepend {
    i {
      vertical-align: middle;
    }
  }

  input {
    &.touchspin {
      padding: 21px 12px;
    }
  }
  .touchspin-vertical-tab {
    .input-group {
      width: 98% !important;
      .input-group-btn-vertical {
        position: relative;
        white-space: nowrap;
        width: 1%;
        vertical-align: middle;
        display: table-cell;
        .bootstrap-touchspin-down {
          margin-top: -2px;
        }
        i {
          position: absolute;
          top: 4px;
          left: 7px;
          font-size: 10px;
          font-weight: normal;
        }
        > .btn {
          display: block;
          float: none;
          width: 100%;
          max-width: 100%;
          padding: 9px;
          margin-left: -1px;
          position: relative;
        }
      }
    }
  }
  .input-group {
    font-family: $font-work-sans, $font-serif;
    .btn {
      border-radius: 0 !important;
    }
  }
  .dropdown-basic {
    .dropdown {
      .dropbtn {
        padding: 12px 30px;
      }
      .dropdown-content {
        z-index: 9999;
      }
    }
  }
}
/**=====================
     19. Touchspin  CSS Ends
==========================**/
