/**=====================
    71. Rating CSS start
==========================**/
ngb-rating {
  &:focus {
    outline: none;
  }
}
.rating-size {
    span {
      font-size: 25px;
    }
    &:focus {
        outline: none;
    }
  }
  .star {
    color: #b0c4de;
    position: relative;
    display: inline-block;
    font-size: 2rem;
  }
  .filled {
    color: $primary-color;
  }
  .bad {
    color: #deb0b0;
  }
  .filled.bad {
    color: $secondary-color;
  }
  .full {
    color: $secondary-color;
  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: $secondary-color;
  }
  .editable-rating {
    position: relative;
    .btn-position {
      position: absolute;
      top: 50px;
      right: 50px;
    }
  }
  
/**=====================
    71. Rating CSS Ends
==========================**/